import React from "react"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  gridContainer,
  projectBoxStyle,
  projectBox__id,
  projectBox__title,
  projectBox__subTitle,
  projectBox__brief,
  projectBox__learnMore,
  projectBox__previewImage,
} from "../sass/personal-projects.module.scss"

const ProjectBox = ({ slug, title, subTitle, previewImage, brief }) => (
  <Link
    to={`/personal-project/${slug}`}
    state={{
      modal: true,
    }}
    className={projectBoxStyle}
  >
    <div id={slug} className={projectBox__id}></div>
    <div className={projectBox__title}>{title}</div>
    <div className={projectBox__subTitle}>{subTitle}</div>
    {previewImage && (
      <div className={projectBox__previewImage}>
        <Img alt={`${title}-preview`} fluid={previewImage.fluid} />
      </div>
    )}
    <div className={projectBox__brief}>{brief}</div>
    <div className={projectBox__learnMore}>{`Learn more about ${title}`}</div>
  </Link>
)

const PersonalProjects = ({ data: allContentfulPersonalProjects }) => {
  const {
    allContentfulPersonalProjects: { edges },
  } = allContentfulPersonalProjects

  const { projects } = edges[0].node
  const seoDescription = projects.map(p => p.title).join(", ")

  return (
    <Layout>
      <SEO
        title="Guillaume Bouffard | Personal projects"
        description={seoDescription}
      />
      <div className={gridContainer}>
        {projects.map(project => (
          <ProjectBox key={project.title} {...project} />
        ))}
      </div>
    </Layout>
  )
}

export default PersonalProjects

export const query = graphql`
  query PersonalProjectsQuery {
    allContentfulPersonalProjects {
      edges {
        node {
          projects {
            slug
            title
            subTitle
            previewImage {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            brief
          }
        }
      }
    }
  }
`
